import type {
  ProductSearchItemInterface,
} from '@ui/components/UiProductSearchItem/UiProductSearchItem.types'
import type { SearchSuggestionsProductsQueryProductsItemsItem } from '@ecom/types/types'

export const ImageDefaults = {
  thumbnail: {
    width: 80,
    height: 80,
  },
}

export interface M2ProductSearchItemProps
  extends Omit<
    ProductSearchItemInterface,
    'labels' | 'product' | 'isUserLoggedIn'
  > {
  product: SearchSuggestionsProductsQueryProductsItemsItem
  globalDataLoading?: boolean
  individualProductsDataLoading?: boolean
  refetchCart?: boolean
}
