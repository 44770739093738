<template>
  <UiProductSearchItem
    :product="{
      name: props.product?.name ?? '',
      link: productLink(product?.canonical_url ?? ''),
      thumbnail: {
        src: props.product?.thumbnail?.url ?? '',
        width: ImageDefaults.thumbnail.width,
        height: ImageDefaults.thumbnail.height,
      },
      price: finalPrice ?? 0,
      sku: props.product?.sku ?? '',
      barcode: props.product?.barcode ?? '',
      customerProductCode: individualProductAlias ?? '',
      stockQuantityLabel,
      availabilityLabel: productQuantityData?.availability_label,
      quantityMoreThanZero: isAvailableQuantityMoreThanZero,
    }"
    show-prices
    :show-customer-product-code="shouldShowCustomerComponents"
    :show-stock-quantity-label="shouldShowCustomerComponents"
    :show-availability-label="shouldShowCustomerComponents"
    :show-add-to-cart-button="shouldShowCustomerComponents"
    :show-global-placeholders="!props.product || props.globalDataLoading"
    :show-customer-data-placeholders="shouldShowCustomerDataPlaceholders"
    :show-product-not-available-dot="props.product.product_typename !== ProductType.VIRTUAL"
    :index="props.index"
  >
    <template #prices="{ isPriceAssigned }">
      <div>
        <template v-if="isPriceAssigned">
          <p class="whitespace-nowrap text-xs">
            {{ labels.priceForSinglePackage }}
          </p>
          <p class="text-sm font-bold">
            <Price :value="finalPrice" />
          </p>
        </template>

        <M2RedirectToContactPageButton
          v-else
          :title="t('ecom_ask_for_the_product')"
          variant="primary"
          :sku="props.product?.sku ?? ''"
          :show-icon="false"
        >
          <UiIcon
            name="chat-bubble"
            :width="20"
            :height="20"
          />
        </M2RedirectToContactPageButton>
      </div>
    </template>
    <template

      #addToCartButton
    >
      <M2AddToCartButton
        v-if="shouldShowCustomerComponents"
        variant="cart"
        :title="labels?.addToCart"
        :disabled="
          shouldItemBeDisabled({ product: props.product, quantity: productQuantityData?.min_sale_qty })
            || loadingItems.includes(props.product?.uid)
        "
        class="min-w-max flex-1 whitespace-nowrap !px-3.5 md:mt-3"
        @click:add-to-cart="handleAddToCart"
      >
        <UiIcon
          name="add-to-cart-plus-sign-outside"
          :width="29"
          :height="32"
        />
      </M2AddToCartButton>
    </template>
  </UiProductSearchItem>
</template>

<script setup lang="ts">
import { useAppNav } from '@base/components/AppNav/useAppNav'
import { useCart } from '@ecom/composables/checkout/useCart/useCart'
import { useProductPrice } from '@ecom/composables/product/useProductPrice/useProductPrice'
import type { SearchSuggestionsProductsQueryProductsItemsItem } from '@ecom/types/types'
import useProductQuantity from '@ecom/composables/product/useProductQuantity'
import useProductAlias from '@ecom/composables/product/useProductAlias'
import { useProduct } from '@ecom/composables/useProduct'
import { ProductType } from '@ecom/types/product.types'
import type { M2ProductSearchItemProps } from './M2ProductSearchItem.types'
import { ImageDefaults } from './M2ProductSearchItem.types'

const props = withDefaults(defineProps<M2ProductSearchItemProps>(), {
  globalDataLoading: false,
  individualProductsDataLoading: false,
  refetchCart: false,
})

const emit = defineEmits<{
  (e: 'click:add-to-wishlist', item: SearchSuggestionsProductsQueryProductsItemsItem): void
  (e: 'add-to-cart', item: SearchSuggestionsProductsQueryProductsItemsItem): void
}>()

const { isLoggedIn } = useCustomer()
const { productLink } = useAppNav()

const {
  addToCart,
  shouldItemBeDisabled,
  loadingItems,
} = useCart()
const { getProductFinalPrice } = useProductPrice()

const { t } = useI18n()
const { getProductStockQuantityLabel } = useProduct()
const { productQuantityData } = useProductQuantity(props.product)
const individualProductAlias = useProductAlias(props.product)
const stockQuantityLabel = computed(() => getProductStockQuantityLabel(props.product))

async function handleAddToCart() {
  if (!props.product?.uid || !props.product?.sku) {
    return
  }

  await addToCart({ product: props.product, quantity: productQuantityData.value?.min_sale_qty || 1, refetchCart: props.refetchCart })
  emit('add-to-cart', props.product)
}

const labels = computed(() => ({
  addToCart: t('ecom_add_to_cart'),
  notAvailable: t('ecom_product_not_available'),
  priceForSinglePackage: t('ecom_net_price_unit', {
    unit: props.product?.sales_um,
  }),
}))

const finalPrice = computed(() => getProductFinalPrice(props.product))
const isAvailableQuantityMoreThanZero = computed(() => typeof productQuantityData.value?.qty === 'number' && productQuantityData.value.qty > 0)
const shouldShowCustomerComponents = computed(() => isLoggedIn.value && !props.individualProductsDataLoading && Boolean(props.product))
const shouldShowCustomerDataPlaceholders = computed(() => {
  if (!isLoggedIn.value) {
    return false
  }

  return props.individualProductsDataLoading && Boolean(props.product)
})
</script>
